import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiLoginService {

  constructor(private http: HttpClient) {
  }

  login(data) {
    return this.http.post(`${environment.API_ROOT}auth/login`, data)
      .pipe(map((res: any) => res.body));
  }

  restorePass(data) {
    return this.http.post(`${environment.API_ROOT}auth/restore-password`, data);
  }

  sendCode(code: string) {
    return this.http.post(`${environment.API_ROOT}auth/login/code`, {code})
      .pipe(map((res: any) => res.body));
  }
}
