import { Injectable } from '@angular/core';
import { CookieOptions } from '@core/models/cookie-options.model';
import { CookieService } from '@shared/services/cookie.service';

@Injectable()
export class BrowserCookieService implements CookieService {
  private _domain = window.location.hostname;
  setCookie(key: string, value: string, options?: CookieOptions): void {
    const timestamp = Date.now();
    const maxAge = options && options.maxAge ? options.maxAge : 3.154e11;
    const expireTimestamp = timestamp + maxAge;
    const dateString = new Date(expireTimestamp).toUTCString();
    const encodedData = encodeURIComponent(value);
    const domain = options && options.domain ? options.domain : this._domain;
    document.cookie = `${key}=${encodedData}; expires=${dateString}; domain=${domain}; path=/`;
  }

  getCookie(key: string): string {
    const cookieReplace = new RegExp(
      `(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`
    );
    const encodedData = document.cookie.replace(cookieReplace, '$1');
    return decodeURIComponent(encodedData);
  }

  deleteCookie(key: string, options: CookieOptions): void {
    const date = new Date(0);
    const dateString = date.toUTCString();
    const domain = options && options.domain ? options.domain : this._domain;
    document.cookie = `${key}=; expires=${dateString}; domain=${domain}; path=/`;
  }
}
