import { Injectable } from '@angular/core';
import { UserModel } from '@core/store/user/user.model';
import { UserState, UserStore } from '@core/store/user/user.store';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs/internal/Observable';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserQuery extends Query<UserState> {
  getUser$: Observable<UserModel> = this.select(userStore => userStore.user);

  constructor(protected store: UserStore) {
    super(store);
  }
}
