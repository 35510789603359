import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserModel } from '@core/store/user/user.model';
import { UserQuery } from '@core/store/user/user.query';
import { UserStore } from '@core/store/user/user.store';
import { environment } from '@environments/environment';
import { API_ROOT } from '@shared/constants/api-root.const';
import { CookieService } from '@shared/services/cookie.service';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(@Inject(API_ROOT) private _API_ROOT: string,
              private _http: HttpClient,
              private _userQuery: UserQuery,
              private _cookieService: CookieService,
              private _userStore: UserStore) {
  }

  loadUser() {
    return this._http.get(`${this._API_ROOT}user/`);
  }

  getUser() {
    return this.loadUser()
      .subscribe(
        ({body}: any) => {
          this.setUser(body);
          this._userStore.setHasCache(true);
        },
        (err) => {
          this._userStore.update({isLoggedIn: false});
          this._userStore.setError(true);
          if (err.status === 401) {
            this.logout();
          }
        }
      );
  }

  updateUserHandlers(val) {
    this._userStore.update((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          userDefaultHandlers: [...val]
        }
      };
    });
  }

  logout() {

    this._cookieService.deleteCookie('PHPSESSID');
    this._cookieService.deleteCookie(environment.authCookieName);
    this._userStore.resetUser();
    this._userStore.setHasCache(false);
  }

  setUser(user: UserModel) {
    this._userStore.update({user, isLoggedIn: true});
  }
}
