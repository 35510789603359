import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreComponent } from '@core/core.component';
import { CoreModule } from '@core/core.module';
import { BrowserCookieService } from '@core/services/browser-cookie.service';
import { CookieService } from '@shared/services/cookie.service';

declare global {
  interface Window {
      dataLayer:any[];
  }
}

@NgModule({
  imports: [
    CoreModule,
    BrowserAnimationsModule
  ],
  bootstrap: [CoreComponent],
  providers: [
    {
      provide: CookieService,
      useClass: BrowserCookieService
    }
  ]
})
export class AppBrowserModule {
}
