<app-header (toggleMenu)="toggleMenu()" [isMenuOpen]="!isCollapsed" [hideHeader]="hideHeader"></app-header>

<nz-layout>
  <ng-container *ngIf="!hideHeader">
    <nz-sider nzCollapsible
            [nzWidth]="240"
            [nzCollapsedWidth]="0"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <ul class="nav">
      <li class="nav-title">
        EVO APPS
      </li>
      <li>
        <a href="https://apps.evo-jet.com/fuel/search/airports" target="_blank">
          Fuel Web
        </a>
      </li>
      <li>
        <a href="https://www.flight-compass.com/trips/" target="_blank">
          Flight Compass
        </a>
      </li>
      <li>
        <a routerLink="handling-estimate">
          Airport Fee Estimator
        </a>
      </li>
      <li>
        <a routerLink="/">
          Airport Briefings
        </a>
      </li>
      <li>
        <a href="https://flyevo.com/airport-briefing-app/" target="_blank">
          3D Airport Briefing
        </a>
      </li>
      <li>
        <a href="https://notams.evo-jet.com/" target="_blank">
          NOTAMizer
        </a>
      </li>
      <li class="nav-title">
        24/7 OPS CENTER
      </li>
      <li>
        <a href="tel:+14154046104">
          +1 415 404 6104
        </a>
      </li>
      <li>
        <a href="mailto:opscenter@flyevo.com">
          opscenter@flyevo.com
        </a>
      </li>
      <li class="nav-title">
        24/7 FUEL DISPATCH
      </li>
      <li>
        <a href="tel:+14154046133">
          +1 415 404 6133
        </a>
      </li>
      <li>
        <a href="mailto:orderfuel@flyevo.com">
          orderfuel@flyevo.com
        </a>
      </li>
    </ul>
  </nz-sider>
  </ng-container>

  <nz-layout>
    <h1 class="main-static-title" *ngIf="breadcrumbs && !hideHeader">
      EVO Apps / {{breadcrumbs}}
    </h1>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>
      <ul>
        <li>
          <a href="https://flyevo.com/privacy-policy" target="_blank">Privacy Policy</a>
        </li>
        <li>
          <a href="https://flyevo.com/terms-of-service" target="_blank">Terms of Service</a>
        </li>
      </ul>
    </nz-footer>
  </nz-layout>

</nz-layout>
