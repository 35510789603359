import { Injectable } from '@angular/core';
import { UserModel } from '@core/store/user/user.model';
import { Store, StoreConfig } from '@datorama/akita';

export interface UserState {
  user: UserModel;
  loading: boolean;
  isLoggedIn: boolean;
}

export const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  loading: false
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'userStore',
  cache: {
    ttl: 3600000
  }
})
export class UserStore extends Store<UserState> {
  constructor() {
    super(initialState);
  }

  resetUser() {
    this.update(initialState);
  }
}
