import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_ROOT } from '@shared/constants/api-root.const';
import { AUTH_COOKIE_NAME, AUTH_HEADER_NAME } from '@shared/constants/auth-name.const';
import { CookieService } from '@shared/services/cookie.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  private _authHeaderName = AUTH_HEADER_NAME;
  private _authCookieName = AUTH_COOKIE_NAME;
  private defaultUpdateOptions = { withCredentials: true };

  constructor(
    @Inject(API_ROOT) private _API_ROOT: string,
    private _cookieService: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userToken = this._cookieService.getCookie(this._authCookieName);

    if (req.url.includes(this._API_ROOT, 0) && userToken) {
      const headers = {
        [this._authHeaderName]: userToken
      };

      return next.handle(req.clone({
        ...this.defaultUpdateOptions,
        setHeaders: headers
      }));
    }
    return next.handle(req.clone({ ...this.defaultUpdateOptions }));
  }
}
