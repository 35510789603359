import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { UserQuery } from '@core/store/user/user.query';
import { UserService } from '@core/store/user/user.service';
import { environment } from '@environments/environment';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { CookieService } from '@shared/services/cookie.service';
import { Observable } from 'rxjs/internal/Observable';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _userQuery: UserQuery,
    private _userService: UserService,
    private _cookieService: CookieService,
    @Optional() @Inject(RESPONSE) private _res,
    @Inject(PLATFORM_ID) private _platformId
  ) {
  }

  somethingWentWrong() {
    if (isPlatformServer(this._platformId)) {
      this._res.status(403);
    }

    this._router.navigate(['login']);
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    const token = this._cookieService.getCookie(environment.authCookieName);

    return this._userService.loadUser()
      .pipe(
        map(() => {
          if (!token) {
            this.somethingWentWrong();
          }

          return !!token;
        }),
        catchError(() => {
          this.somethingWentWrong();

          return EMPTY;
        })
      );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
