import { Injector, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '@core/store/user/user.service';
import { environment } from '@environments/environment';
import { CookieService } from '@shared/services/cookie.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export function appInitializerFactory(
  injector: Injector,
  cookieService: CookieService,
  userService: UserService,
  titleService: Title
) {
  return () => {
    const router: Router = injector.get(Router);
    const activatedRoute: ActivatedRoute = injector.get(ActivatedRoute);
    const _platformId = injector.get(PLATFORM_ID);

    if (isPlatformBrowser(_platformId)) {
      router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          map(() => activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        )
        .subscribe(({pageName}) => {
          if (pageName !== 'private') {
            titleService.setTitle(pageName);
          }
        });
    }

    // update user
    router.events
      .pipe(
        filter(e => e instanceof NavigationStart)
      )
      .subscribe(
        (e) => {

          const token = cookieService.getCookie(environment.authCookieName);
          if (token) {
            userService.getUser();
          }
        });
  };
}
