import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@core/store/user/user.service';
import { environment } from '@environments/environment';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { CookieService } from '@shared/services/cookie.service';
import { Observable } from 'rxjs/internal/Observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiLoginService } from 'app/login/login.service';
import { AUTH_COOKIE_NAME } from '@shared/constants/auth-name.const';

const uderData = {
  name: 'estimator.for.am@evo-jet.com',
  key: 'tasjet-0siwve-voRcuj'
}

@Injectable({ providedIn: 'root' })
export class HandingFunnelGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private _userService: UserService,
    private service: ApiLoginService,
    private cookieService: CookieService,
    @Optional() @Inject(RESPONSE) private _res,
    @Inject(PLATFORM_ID) private _platformId
  ) {
  }

  somethingWentWrong() {
    if (isPlatformServer(this._platformId)) {
      this._res.status(403);
    }

    this._router.navigate(['handling-quote-funnel-error']);
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    if (isPlatformServer(this._platformId)) {
      return of(true);
    } else {
      let req;
      const token = this.cookieService.getCookie(AUTH_COOKIE_NAME);

      if (document && !document?.referrer.includes('airplanemanager')) {
        this._router.navigate(['login'], { queryParams: { apm: 'redirect_failed' } });
        return EMPTY;
      }

      if (token) {
        req = this._userService.loadUser()
      } else {
        req = this.service.login({ username: uderData.name, plainPassword: uderData.key })
          .pipe(
            map(res => {
              if (res && Array.isArray(res) && !res.length) {
                this.somethingWentWrong();
                return EMPTY
              }

              this.cookieService.setCookie(AUTH_COOKIE_NAME, res.apiKey);
              this._userService.setUser(res);
              return !!res
            })
          )
      }

      return req
        .pipe(
          map((res) => {
            return !!res
          }),
          catchError(() => {
            this.somethingWentWrong();

            return EMPTY;
          })
        )
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
