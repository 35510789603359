<header class="flex justify-content-between">
  <div class="menu flex">
    <span class="menu-button"
          *ngIf="!hideHeader"
          [class.open]="isMenuOpen"
          (click)="onToggleMenu()">
      <span class="lines">
        <span class="line"></span>
      </span>
    </span>

    <a href="/" class="logo flex align-items-center" *ngIf="!hideHeader">
      <img style="width: 96px; height:auto" src="assets/images/evo-logo-white-v3.png" alt="Logo">
    </a>

    <div class="logo flex align-items-center" *ngIf="hideHeader">
      <img style="width: 96px; height:auto" src="assets/images/evo-logo-white-v3.png" alt="Logo">
    </div>
  </div>

  <div class="user-info flex" *ngIf="!hideHeader">
    <ng-container *ngIf="user | async; let userData; else login">
      <button class="flex align-items-center "
              nz-dropdown
              nzTrigger="click"
              [nzPlacement]="'bottomRight'"
              [nzDropdownMenu]="menu">
        <img src="assets/images/user-profile.svg" alt="Profile">
        <span>{{userData.name}}</span>
      </button>
    </ng-container>
  </div>
</header>

<ng-template #login>
  <button class="flex align-items-center" (click)="onLogin()">
    <img src="assets/images/user-profile.svg" alt="Login">
    <span>Login</span>
  </button>
</ng-template>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item><a routerLink="profile">My Profile</a></li>
    <li nz-menu-item (click)="onLogout()">Log Out</li>
  </ul>
</nz-dropdown-menu>
