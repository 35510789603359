import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '@core/store/user/user.model';
import {UserQuery} from '@core/store/user/user.query';
import {UserService} from '@core/store/user/user.service';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  @Input() isMenuOpen: boolean;
  @Input() hideHeader: boolean;
  @Output() toggleMenu = new EventEmitter<void>();

  user = this.userQuery.getUser$;

  constructor(private readonly _router: Router,
              private readonly userQuery: UserQuery,
              private readonly userService: UserService) {
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  onLogin() {
    this._router.navigateByUrl('login');
  }

  onLogout() {
    this.userService.logout();
    this._router.navigateByUrl('login');
  }
}
