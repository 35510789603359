import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestQuoteApiService {

  constructor(private http: HttpClient) { }

  getRequestQuote(id, { flightType, departureType }) {
    return this.http.get(`${environment.API_ROOT}fuel-price/${id}?flightType=${flightType}&departureType=${departureType}`)
      .pipe(map((res: any) => res && res.body));
  }
}
