import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-error-template-handling',
  templateUrl: './error-template-handling.component.html',
  styleUrls: ['./error-template-handling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorTemplateHandlingComponent {

}
