import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ErrorTemplateComponent } from '@core/components/error-template/error-template.component';
import { coreRoutes } from '@core/core.routes';
import { appInitializerFactory } from '@core/services/app-initializer-factory';
import { AuthHeaderInterceptor } from '@core/services/auth-header-interceptor.service';
import { PreloadingStrategyService } from '@core/services/preloading-strategy.service';
import { UserService } from '@core/store/user/user.service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '@environments/environment';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { API_ROOT } from '@shared/constants/api-root.const';
import { RequestQuoteModule } from '@shared/modals/request-quote/request-quote.module';
import { CookieService } from '@shared/services/cookie.service';
import { HeaderComponent } from './components/header/header.component';
import { CoreComponent } from './core.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';


@NgModule({
  declarations: [CoreComponent, HeaderComponent, ErrorTemplateComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    TransferHttpCacheModule,
    // SignInModule,
    BrowserModule.withServerTransition({appId: 'apcloud-app'}),
    RouterModule.forRoot(coreRoutes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadingStrategyService,
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload'
    }),
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    RequestQuoteModule,
    NzLayoutModule
  ],
  providers: [
    PreloadingStrategyService,
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, CookieService, UserService, Title],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true
    },
    {
      provide: API_ROOT,
      useValue: environment.API_ROOT
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true
      }
    }
  ]
})
export class CoreModule {
}
