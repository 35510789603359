import { Routes } from '@angular/router';
import { ErrorTemplateComponent } from '@core/components/error-template/error-template.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { ErrorTemplateHandlingComponent } from './components/error-template-handling/error-template-handling.component';
import { HandingFunnelGuard } from './guards/handling-estimate-funnel.guard';

export const coreRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('app/login/login.module').then(m => m.LoginModule),
    data: {
      pageName: 'BriefMe.aero | Login'
    },
  },
  {
    path: 'login-code',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
    data: {
      pageName: 'BriefMe.aero | Login Code'
    },
  },
  {
    path: 'restore-pass',
    loadChildren: () => import('app/restore-pass/restore-pass.module').then(m => m.RestorePassModule),
    data: {
      pageName: 'BriefMe.aero | Password Settings'
    },
  },
  {
    path: 'estimator-for-am',
    loadChildren: () => import('app/handling-quote-funnel-page/handling-quote-funnel-page.module').then(m => m.HandlingQuoteFunnelPageModule),
    canActivate: [HandingFunnelGuard],
    data: {
      pageName: 'BriefMe.aero | Handling Estimate',
      hideHeader: true
    },
  },
  {
    path: 'handling-quote-funnel-error',
    component: ErrorTemplateHandlingComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
        data: {
          pageName: 'BriefMe.aero | Main',
          breadcrumbs: 'Airport Briefings'
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('app/profile/profile.module').then(m => m.ProfileModule),
        data: {
          pageName: 'BriefMe.aero | Profile',
          breadcrumbs: 'Profile'
        },
      },
      {
        path: 'search',
        loadChildren: () => import('app/search-page/search-page.module').then(m => m.SearchPageModule),
        data: {
          pageName: 'BriefMe.aero | Airport Search',
          breadcrumbs: 'Search'
        },
      },
      {
        path: 'airport/:id',
        loadChildren: () => import('app/airport/airport.module').then(m => m.AirportModule),
        data: {
          pageName: 'private',
          breadcrumbs: 'Airport Briefings'
        },
      },
      {
        path: 'handling-estimate',
        loadChildren: () => import('app/handling-quote-page/handling-quote-page.module').then(m => m.HandlingQuotePageModule),
        data: {
          pageName: 'BriefMe.aero | Handling Estimate',
          breadcrumbs: 'Handling Estimate'
        },
      },
    ]
  },
  {
    path: '**',
    component: ErrorTemplateComponent
  }
];
