<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-8">
			<h1>
			  Whoops! <br>
			  This taxiway is not available
			</h1>
			<img class="img" src="assets/images/error.gif">
			<p>
			  The link you followed is broken, or the page has been removed
			</p>
			<p>
				<a [routerLink]="'/'" routerLinkActive="active">
					<button class="btn btn-primary">
				      Return to homepage
				    </button>
				</a>
			</p>
		</div>
	</div>
</div>
