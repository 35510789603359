import { Injectable } from '@angular/core';

@Injectable()
export abstract class CookieService {
  abstract setCookie(key: string, value: string, options?: {maxAge: number, domain: string}): void;

  abstract getCookie(key: string): string;

  abstract deleteCookie(key: string, options?: {maxAge: number, domain: string}): void;
}
