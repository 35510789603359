import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit {
  isCollapsed = true;
  breadcrumbs = ''
  hideHeader = false;
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((e) => e instanceof ActivationStart)
    ).subscribe(( ) => {
      this.isCollapsed = true;
    });

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe(({breadcrumbs, hideHeader}) => {
        this.hideHeader = hideHeader
        this.breadcrumbs = breadcrumbs
      });
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }
}
